import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "techniques",
    path: "accueil / nos métiers / techniques / Architecte"
  },
  title: "Architecte",
	missions:[
		{
		  text: "Concevoir et réaliser l’architecture poste de travail, infrastructure ou applicative" 
		},
		{
		  text: "Assurer la cohérence entre les différentes composantes (poste de travail ou applicatives)" 
		},
		{
		  text: "Conduire les études techniques des projets" 
		},
	],
  
	key_skills: [
		{
		  text: "Planifier" 
		},
		{
		  text: "Organiser" 
		},
		{
		  text: "Communication écrite et orale" 
		},
		{
		  text: "Etre consciencieux" 
		},
		{
		  text: "Etre autonome" 
		},
		{
		  text: "Expertise technique poste de travail, infrastructure ou applicative Connaissances techniques des différents outils et langages de développement orientés outils" 
		},
		{
		  text: "Très bonnes connaissances du fonctionnement d’une DSI" 
		},
		{
		  text: "Maîtrise une/plusieurs méthode(s) de conception" 
		},
		{
		  text: "Maîtrise de l’anglais technique obligatoire" 
		},
	],	 
	prerequisites:[
		{
		  text: "Expérience professionnelle validée d’ingénieur" 
		},
		{
		  text: "Posséder une certification éditeur dans au moins un domaine d’expertise" 
		},
	],
	activities:[
		{
		  text: "Assurer l’audit poste de travail, infrastructure ou applicative" 
		},
		{
		  text: "Définir des solutions techniques" 
		},
		{
		  text: "Assurer le design et la conception d’architecture" 
		},
		{
		  text: "Rédiger le cahier des charges techniques" 
		},
		{
		  text: "Rédiger les spécifications techniques" 
		},
		{
		  text: "Conduire les projets dans les respects des engagements de qualité, délai et coût" 
		},
		{
		  text: "Assurer une veille technologique des produits" 
		},
		{
		  text: "Intervenir sur des missions de conseil" 
		},
		{
		  text: "Intervenir en avant-vente et sur les réponses à Appels d’Offres" 
		},
	],
}
const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;